.formik-form {
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 10px;
    .field-error {
      font-size: 13px;
      color: red;
    }
    label {
      color: $label-color;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    input,
    select {
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #d4dae9;
      border-radius: 10px;
      padding: 10px;
      outline: none;
      font-size: 12px;
    }
    input.has-error {
      border: 1px solid red;
    }
    .p-md-4 {
      padding: 0.5rem !important;
    }
  }
  .checkbox-group {
    display: flex;
    .p-checkbox-box {
      border: 1px solid #d4dae9;
      border-radius: 5px;
    }
    label {
      color: #1f2d3d;
      font-size: 12px;
      line-height: 16px;
      padding-top: 2px;
      margin-left: 16px;
    }
  }
  .has-error {
    color: red;
    font-size: 12px;
  }
  input::placeholder {
    font-size: 12px;
    color: $label-color;
    opacity: 0.5;
  }
  .form-footer {
    width: 100%;
    padding: 0.5rem;
    .cancel-button {
      border: 1px solid #d3d3ed;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      color: $label-color;
      font-size: 14px;
      padding: 9px;
      background: white;
    }
    .submit-button {
      background: #cf3339;
      border-radius: 5px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: 800;
      color: #ffffff;
      width: 100%;
      padding: 10px;
      border: 1px solid #cf3339;
      outline: none !important;
    }
  }
}

.cancel-button {
  border: 1px solid #d3d3ed;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  color: $label-color;
  font-size: 14px;
  padding: 9px;
  background: white;
}
.submit-button {
  background: #cf3339;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  font-weight: 800;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  border: 1px solid #cf3339;
  outline: none !important;
}
