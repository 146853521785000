@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "./theme/assets/scss/variables.scss";
@import "~bootstrap/scss/mixins/_buttons";

// Infinity App custom variables
$infinity-primary: #CF3339;
$infinity-secondary: #E5E9F2;
$infinity-secondary-dark: #8492A6;

$infinity-orange: #cc521d;
$infinity-blue: #5664d2;

// forms
$label-color: #535574; // Previous theme color: #7D83A2;

@import "./styles/formik-formik-styles.scss";

.btn-infinity-primary {
  font-weight: bolder;
  font-size: 1.1rem;
  @include button-variant($infinity-primary, $infinity-primary);
}

.btn-infinity-secondary {
  font-weight: bold;
  font-size: 1.1rem;
  @include button-variant($infinity-secondary, $infinity-secondary);
}

.carousel-indicators li {
  background-color: red;
}

.infinity-input.form-control:focus {
  border-color: $infinity-secondary;
  box-shadow: 0 0 0 0.2rem $infinity-secondary;
}

.infinity-input.form-control.is-valid,
.infinity-input.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}

.infinity-input {
  max-width: 333px;
  padding-left: 0;
  padding-right: 0;
}

.infinity-input-nl {
  width: 100%;
}

.infinity-input-nl.form-control:focus {
  border-color: $infinity-secondary;
  box-shadow: 0 0 0 0.2rem $infinity-secondary;
}

.infinity-input-nl.form-control.is-valid,
.infinity-input-nl.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}

.infinity-input-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #cf3339;
}

.infinity-input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $label-color;
}

.fp-container {
  background: #f8f8f8ad;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 9001;
}

.fp-container .fp-spinner {
  color: $infinity-primary;
}

.fp-container .fp-spinner-label {
  color: $infinity-primary;
  font-weight: bold;
}

.custom-container-padding {
  padding: 2rem 3rem !important;
}

.university-container-padding {
  padding: 1rem 3rem 1rem 3rem !important;
}

footer img.university-logo {
  max-height: 1.6rem;
}

.on-boarding-footer {
  bottom: 0;
  width: 100%;
  padding: 0.5rem 4rem;
  color: $footer-color;
  box-shadow: $box-shadow;
  background-color: $footer-bg;
}

// Confirm modal React Prime customization
.p-confirm-dialog {
  .p-dialog-header {
    justify-content: center !important;

    span {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 30px !important;
      text-align: center !important;
      text-transform: capitalize !important;
      color: #1f2d3d;
    }
  }

  .p-dialog-content {
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: $label-color;
    }
  }

  .p-dialog-footer {
    text-align: center !important;

    .p-confirm-dialog-reject {
      border: 1px solid #d4dae9 !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      color: $label-color !important;
      box-shadow: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &:hover {
        opacity: 85% !important;
      }
    }

    .p-confirm-dialog-accept {
      margin-left: 1em !important;
      background: #cf3339 !important;
      border-radius: 10px !important;
      color: #ffffff !important;
      box-shadow: none !important;
      border: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &:hover {
        opacity: 85% !important;
      }
    }
  }
}

.functional-modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  color: #1F2D3D;
}

.mw-333 {
  max-width: 333px;
}

.navbar-brand-box {
  padding: 0 1rem;
}


// modal styling
// .p-component-overlay {
//   .p-dialog {
//     @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
//       width: 730px;
//     }

//     @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
//       width: 100%;
//     }
//   }
// }


.p-dropdown-panel {
  div {
    ul {
      padding-left: 0;
    }
  }
}

#nav-menu-switcher-outline {

  .btn {
    border-width: 3px;
  }

  .btn-outline-workstation {
    @include button-outline-variant($infinity-blue, darken($infinity-blue, 30%));
  }

  .btn-outline-inventory {
    @include button-outline-variant($infinity-orange, darken($infinity-orange, 30%));
  }
}

.menu-title {
  opacity: 0.6;

  &.workstation {
    background-color: $infinity-blue;
  }

  &.inventory {
    background: $infinity-orange;
  }

  &.menu-section-title {
    color: var(--#{$prefix}white);
    text-transform: initial;
    font-size: 1.1rem;
    opacity: 1;
    padding-top: 8px !important;
    padding-bottom: 8px !important;

    span {
      margin-left: 2rem;
    }
  }
}

.workstation-sidebar-menu {

  .mm-active {
    color: darken($infinity-blue, 20%) !important;

    >a {
      color: darken($infinity-blue, 20%) !important;

      i {
        color: darken($infinity-blue, 20%) !important;
      }
    }

    >i {
      color: darken($infinity-blue, 20%) !important;
    }
  }

  .active {
    color: darken($infinity-blue, 20%) !important;

    i {
      color: darken($infinity-blue, 20%) !important;
    }
  }

}


.inventory-sidebar-menu {

  .mm-active {
    color: $infinity-orange !important;

    >a {
      color: $infinity-orange !important;

      i {
        color: $infinity-orange !important;
      }
    }

    >i {
      color: $infinity-orange !important;
    }

  }

  .active {
    color: $infinity-orange !important;

    i {
      color: $infinity-orange !important;
    }
  }
}

body[data-sidebar="dark"] {

  .menu-title {

    &.workstation {
      color: var(--#{$prefix}white);
    }

    &.inventory {
      color: var(--#{$prefix}white);
    }

  }
}

.p-button.p-component {
  border-radius: 8px !important;
}


.menu-switcher {
  --menu-switcher-width: 22rem;
  --menu-switcher-height: 2.8rem;
  --menu-switcher-border: #aaa;
  --menu-switcher-background: var(--#{$prefix}gray-300);
  --menu-switcher-inactive: #bbb;
  --menu-switcher-color1: #{$infinity-blue};
  --menu-switcher-color2: #{$infinity-orange};

  position: relative;
  display: flex;
  width: var(--menu-switcher-width);
  min-width: var(--menu-switcher-width);
  height: var(--menu-switcher-height);
  border-radius: calc(var(--menu-switcher-height) / 2);
  border: 1px solid var(--menu-switcher-border);
  background-color: var(--menu-switcher-background);
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 540px) {
  .menu-switcher {
    width: 100%;
  }
}

.menu-switcher::before {
  content: "";
  position: absolute;
  left: 0;
  width: 50%;
  height: var(--menu-switcher-height);
  border-radius: calc(var(--menu-switcher-height) / 2);
  background-color: var(--menu-switcher-color1);
  transition: all 500ms;
}

.menu-switcher.checked::before {
  left: 50%;
  background-color: var(--menu-switcher-color2);
}

.menu-switcher span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--menu-switcher-inactive);
  transition: color 500ms;
  flex: 1;
}

.menu-switcher span.active {
  color: #fff;
}

#university-header {
  display: flex;
  margin: 0 auto;
  padding: 0 calc(1.5rem / 2) 0 0;
  height: var(--#{$prefix}university-header-height);

  .nav-link {
    font-size: 0.88rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .form-control {
    font-size: 0.9rem;
    padding: 0.2rem 0.6rem;
  }

  .btn-sm {
    padding: 0 0.4rem;
    height: 1.8rem;
  }
}

.block-fade-in {
  animation: blockFadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes blockFadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body[data-bs-theme="dark"] {
  .menu-switcher {
    --menu-switcher-background: var(--#{$prefix}gray-600);
  }

  .form-control {
    background-color: var(--#{$prefix}gray-900);
    color: var(--#{$prefix}gray-200);
  }

  .form-control:disabled {
    background-color: var(--#{$prefix}gray-700);
  }
}