.p-orderlist {
    .p-orderlist-list-container {
        .p-orderlist-list {
            padding: 0;
        }

        .p-orderlist-item {
            padding: 0.3rem 0.8rem;
            font-size: small
        }

        .p-orderlist-header {
            padding: 0.5rem 1rem;
            font-weight: normal;
        }
    }

    .p-orderlist-controls {
        padding: 0.5rem;
        .p-button {
            margin: 0.3rem 0;
        }
    }
}