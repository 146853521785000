.p-picklist {
    .p-picklist-list-wrapper {
        .p-picklist-list {
            padding-left: 0;
        }
        .p-picklist-item {
            padding: 0.3rem 0.8rem;
            font-size: small;
        }
        .p-picklist-header {
            padding: 0.5rem 1rem;
            font-weight: normal;
        }
        .p-picklist-filter-container {
            padding: 0.5rem;
        }
    }
    .p-picklist-transfer-buttons {
        .p-button {
            margin: 0.5rem 0;
        }
    }
}

body[data-bs-theme="light"] {
    .p-highlight {
        background-color: #adc0d7;
    }
}