.on-boarding-section {
    padding: 13em 0;
}

.on-boarding-login-section {
    padding-top: 1em;
}

.on-boarding-section img,
.on-boarding-login-section img {
    width: 12em;
}

.forgot-password {
    font-weight: 500;
    color: #535574;
    font-size: 1.1rem;
}

.sign-up-label {
    color: #535574;
    font-size: 1.1rem;
}

.container-onboarding-padding{
    padding-top: 15px;
    padding-bottom: 15px;
}

.custom-input-margin {
    margin-left: 25px;
}

.info-icon {
    margin-top: 0.38rem;
    margin-left: 0.38rem;
}
