.list-component-toolbar {
    background-color: var(--bs-card-bg) !important;
    border: none !important;
}

.record-action-button {
    background: var(--bs-gray-400);
    border: var(--bs-gray-400);
    /* Black */
    color: #1F2D3D !important;

    &:disabled {
        background: var(--bs-gray-300);
        color: #D4DAE9 !important;
    }

    &:hover {
        opacity: 72% !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.add-record-button {
    background: #CF3339 !important;
    border-radius: 8px !important;
    font-size: 1rem;
    border: var(--bs-gray-300);
    box-shadow: none !important;
    color: #FFFFFF !important;

    &:disabled {
        background: var(--bs-gray-300);
        color: #D4DAE9 !important;
    }

    &:hover {
        opacity: 85% !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.delete-confirm-dialog {
    .p-dialog-content {
        justify-content: center;
        min-height: 120px;
    }
}

.delete-bulk-confirm-dialog {
    .p-dialog-content {
        justify-content: center;
        min-height: 120px;
    }
}

.datatable-crud-base {
    .p-toolbar {
        padding: 0.2rem;
    }
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.6rem 1rem;
}

body[data-bs-theme="dark"] {
    .record-action-button {
        background: var(--bs-gray-500);
        border: var(--bs-gray-500);

        &:disabled {
            background: var(--bs-gray-700);
            border: var(--bs-gray-700);
        }
    }
}