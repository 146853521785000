@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "../theme/assets/scss/variables.scss";

.p-listbox {
    ul.p-listbox-list {
        padding: 0;
        margin: 0;
    }

    &.select-organization-list {
        max-width: 26rem;
        margin-left: auto;
        margin-right: auto;

        .p-listbox-item {
            padding: 0.5rem 2rem;
            font-size: 1.5rem;
            font-weight: 500;
            color: $primary-text-emphasis;
            position: relative; // Added this line to position the pseudo-element

            &:hover {
                background-color: $primary-bg-subtle;

                &::before { // Added this block to display the icon
                    content: "\003E"; // Unicode for '>'
                    position: absolute;
                    left: 0.5rem;
                }
            }
        }
    }
}
